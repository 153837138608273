import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' //引入axios
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/iconfont.css'
Vue.prototype.$axios = axios; //把axios挂载到vue上
Vue.use(ElementUI);
// 引入字体
import '@/assets/css/font.css'
// 引入样式表
import '@/assets/css/styles.css'
// 自适应css
import '@/assets/css/media.css'
import echarts from './utils/echarts'
// import i18n from './i18n'
import NoDataComponent from '@/components/noData'
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.component(NoDataComponent.name, NoDataComponent)
import './filters'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

new Vue({
    router,
    store,
    // i18n,
    render: h => h(App)
}).$mount('#app')