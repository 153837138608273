import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Dashboard',
        component: () =>
            import ('../views/dashboard/home')
    },
    {
        path: '/secure/register',
        name: 'Secure',
        // redirect: '/secure/register',
        component: () =>
            import ('../views/secure/register'),
    },
    {
        path: '/secure/fillInInformation',
        name: 'FillInInformation',
        component: () =>
            import ('../views/secure/fillInInformation')
    },
    {
        path: '/secure/serviceProvider',
        name: 'FillInInformation',
        component: () =>
            import ('../views/secure/serviceProvider') //服务商
    },
    {
        path: '/system',
        name: 'System',
        component: () =>
            import ('../views/system/layout'),
        // redirect: '/system/supplier',
        children: [{
                path: 'supplier',
                name: 'Supplier',
                component: () =>
                    import ('../views/system/supplier/index'),
                children: [{
                        path: 'workbench',
                        name: 'Workbench',
                        component: () =>
                            import ('../views/system/supplier/workbench'),
                        meta: {
                            path: '/system/supplier/index'
                        }
                    },
                    {
                        path: 'applyFinancing',
                        name: 'ApplyFinancing',
                        component: () =>
                            import ('../views/system/supplier/applyFinancing')
                    },
                    {
                        path: 'myAccount',
                        name: 'MyAccount',
                        component: () =>
                            import ('../views/system/supplier/myAccount')
                    },
                    {
                        path: 'myFinancing',
                        name: 'MyFinancing',
                        component: () =>
                            import ('../views/system/supplier/myFinancing')
                    },
                    {
                        path: 'myPartner',
                        name: 'MyPartner',
                        component: () =>
                            import ('../views/system/supplier/myPartner')
                    },
                    {
                        path: 'myCredit',
                        name: 'MyCredit',
                        component: () =>
                            import ('../views/system/supplier/myCredit')
                    },
                    {
                        path: 'myCustomers',
                        name: 'MyCustomers',
                        component: () =>
                            import ('../views/system/supplier/myCustomers')
                    },
                    {
                        path: 'myService',
                        name: 'MyService',
                        component: () =>
                            import ('../views/system/supplier/myService'),
                        meta: {
                            path: '/system/supplier/MyService'
                        }
                    },
                    {
                        path: 'functionDefinition',
                        name: 'FunctionDefinition',
                        component: () =>
                            import ('../views/system/supplier/functionDefinition'),
                    },
                    {
                        path: 'optimizeMyCredit',
                        name: 'OptimizeMyCredit',
                        component: () =>
                            import ('../views/system/supplier/optimizeMyCredit'),
                        meta: {
                            path: '/system/supplier/OptimizeMyCredit'
                        }
                    },
                    {
                        path: 'risk',
                        name: 'Risk',
                        component: () =>
                            import ('../views/system/supplier/risk'),
                        meta: {
                            path: '/system/supplier/risk'
                        }
                    },
                ]
            },
            {
                path: 'service',
                name: 'service',
                component: () =>
                    import ('../views/system/service/index'),
                children: [
                    {
                        path: 'workbench',
                        name: 'Workbench',
                        component: () =>
                            import ('../views/system/service/workbench'),
                    },
                    {
                        path: 'ratingsServices',
                        name: 'RatingsServices',
                        component: () =>
                            import ('../views/system/service/ratingsServices'),
                    },
                    
                ],
            },   
            {
                path: 'investor',
                name: 'investor',
                component: () =>
                    import ('../views/system/investor/index'),
                children: [{
                        path: 'workbench',
                        name: 'Workbench',
                        component: () =>
                            import ('../views/system/investor/workbench'),
                    },
                    {
                        path: 'publicResource',
                        name: 'PublicResource',
                        component: () =>
                            import ('../views/system/investor/publicResource')
                    },
                    {
                        path: 'privateResources',
                        name: 'PrivateResources',
                        component: () =>
                            import ('../views/system/investor/privateResources')
                    },
                    {
                        path: 'riskPool',
                        name: 'RiskPool',
                        component: () =>
                            import ('../views/system/investor/riskPool')
                    },
                    {
                        path: 'postLoanManagement',
                        name: 'PostLoanManagement',
                        component: () =>
                            import ('../views/system/investor/postLoanManagement')
                    },
                    {
                        path: 'myService',
                        name: 'MyService',
                        component: () =>
                            import ('../views/system/investor/myService'),
                    },
                    {
                        path: 'functionDefinition',
                        name: 'FunctionDefinition',
                        component: () =>
                            import ('../views/system/investor/functionDefinition'),
                    },
                ]
            },
            {
                path: 'coreEnterprise',
                name: 'CoreEnterprise',
                component: () =>
                    import ('../views/system/coreEnterprise/index'),
                children: [{
                        path: 'workbench',
                        name: 'Workbench',
                        component: () =>
                            import ('../views/system/coreEnterprise/workbench'),
                        meta: {
                            path: '/system/coreEnterprise/index'
                        }
                    },
                    {
                        path: 'mySupplier',
                        name: 'MySupplier',
                        component: () =>
                            import ('../views/system/coreEnterprise/mySupplier'),//我的供应商
                    },
                    {
                        path: 'mySupplier/detail',
                        name: 'MySupplier/detail',
                        component: () =>
                            import ('../views/system/coreEnterprise/mySupplier/detail'),
                    },
                    {
                        path: 'myClient',
                        name: 'MyClient',
                        component: () =>
                            import ('../views/system/coreEnterprise/myClient'),//我的客户
                    },
                    {
                        path: 'myClient/detail',
                        name: 'myClient/detail',
                        component: () =>
                            import ('../views/system/coreEnterprise/myClient/detail'),
                    },
                    {
                        path: 'myFinancing',
                        name: 'MyFinancing',
                        component: () =>
                            import ('../views/system/coreEnterprise/myFinancing'),//我的融资
                    },
                    {
                        path: 'myService',
                        name: 'MyService',
                        component: () =>
                            import ('../views/system/coreEnterprise/myService'),
                    },
                    {
                        path: 'myCustomers',
                        name: 'MyCustomers',
                        component: () =>
                            import ('../views/system/coreEnterprise/myCustomers'),//我的获客
                    },
                    {
                        path: 'functionDefinition',
                        name: 'FunctionDefinition',
                        component: () =>
                            import ('../views/system/coreEnterprise/functionDefinition'),//功能定义
                    },
                    {
                        path: 'applyFinancing',
                        name: 'ApplyFinancing',
                        component: () =>
                            import ('../views/system/coreEnterprise/applyFinancing'),//我的发票
                    },
                    {
                        path: 'myInvest',
                        name: 'MyInvest',
                        component: () =>
                            import ('../views/system/coreEnterprise/myInvest'),//我的投资
                    },
                ]
            },
            {
                path: 'personalCenter',
                name: 'PersonalCenter',
                component: () =>
                    import ('../views/system/personalCenter/index'),
                children: [{
                        path: 'accountManagement',
                        name: 'AccountManagement',
                        component: () =>
                            import ('../views/system/personalCenter/accountManagement'),
                        meta: {
                            path: '/system/personalCenter/index'
                        }
                    },
                    {
                        path: 'accountSettings',
                        name: 'AccountSettings',
                        component: () =>
                            import ('../views/system/personalCenter/accountSettings'),
                    },
                    {
                        path: 'myRating',
                        name: 'MyRating',
                        component: () =>
                            import ('../views/system/personalCenter/myRating'),
                    },
                    {
                        path: 'myNotice',
                        name: 'MyNotice',
                        component: () =>
                            import ('../views/system/personalCenter/myNotice'),
                    },
                ]
            }
        ]
    },
]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    // mode: 'history',
    // base: process.env.NODE_ENV == "development" ? process.env.BASE_URL : 'kwksystem',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, saveTop) {
        if (saveTop) {
            return saveTop;
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default router