<template>
  <div class="flexCenter emptyTd">
    {{ text }}
  </div>
</template>
<script>
export default {
  name: 'NoData',
  data () {
    return {
      text: '暂无数据',
    }
  }
}
</script>
