import Vue from 'vue'
Vue.filter('NumFormat', function (num) {
  const newNum = parseInt(num)
  if (newNum) {
    // eslint-disable-next-line eqeqeq
    if ((num + '').trim() == '') {
      return ''
    }
    // eslint-disable-next-line eqeqeq
    if (String(num).indexOf(',') == -1) {
      num = parseFloat(num)
      num = num.toFixed(2)
      num = parseFloat(num)
      num = num.toLocaleString()
    } else {
      num = num.replace(/,/gi, '')
      num = parseFloat(num).toLocaleString()
    }
  }
  return num
})
Vue.filter('ParenctNum', function (num) {
  const newNum = parseFloat(num)
  if (newNum) {
    return Number(newNum * 100).toFixed(2)
  } else {
    return num
  }
})
Vue.filter('ParenctNumTwo', function (num) {
  const newNum = parseFloat(num)
  if (newNum) {
    return Number(newNum * 100).toFixed(2) + '%'
  } else {
    return num
  }
})
Vue.filter('PositiveNumber', function (num) {
  const newNum = parseFloat(num)
  if (newNum) {
    return (-newNum)
  } else {
    return num
  }
})

