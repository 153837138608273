import * as echarts from 'echarts/core'
import { BarChart, PieChart, LineChart, ScatterChart, LinesChart, GaugeChart, GraphChart, SankeyChart, FunnelChart, TreeChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  DataZoomComponent,
  ToolboxComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  DataZoomComponent,
  ToolboxComponent,
  BarChart,
  PieChart,
  CanvasRenderer,
  LineChart,
  ScatterChart,
  LinesChart,
  GaugeChart,
  GraphChart,
  SankeyChart,
  FunnelChart,
  TreeChart
])

export default echarts
